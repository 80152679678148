<template>
  <v-dialog v-model="dialog" max-width="850px">
    <v-card>
      <v-card-title>
        <span class="headline">{{ title }}</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-form ref="mForm">
            <v-row class="mt-2">
              <slot name="form" v-bind:item="editedItem" />
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn color="blue darken-1" text @click="close">
          Cancel
        </v-btn>
        <v-btn color="blue darken-1" text @click="save">
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    title: { type: String, default: 'Form' },
    value: Object,
    defaultValue: Object
  },
  data() {
    return {
      dialog: false,
      editedItem: this.defaultValue,
      options: null
    }
  },
  watch: {
    dialog(val) {
      if (val === false) {
        this.close()
      }
    },
  },
  created() {
    this.$emit('input', {
      show: this.show,
      close: this.close,
    })
  },
  methods: {
    show(item, options) {
      if (item) {
        this.editedItem = Object.assign({}, item)
      }
      if (options) {
        this.options = options
      }

      this.dialog = true
    },
    close() {
      this.dialog = false
      this.editedItem = Object.assign({}, this.defaultValue)
      this.options = null
    },
    save() {
      this.$emit('save', this.editedItem, this.options)
      this.close()
    },
  },
}
</script>
