// Imports
import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      redirect: { path: '/devices' },
      component: () => import('../layouts/default/Index'),
      children: [
        {
          path: 'devices',
          name: 'Devices',
          component: () => import('../views/Devices'),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: 'scripts',
          name: 'Scripts',
          component: () => import('../views/CompanyScripts.vue'),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: 'configs',
          name: 'Configs',
          component: () => import('../views/CompanyConfigs.vue'),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: 'apps',
          name: 'Apps',
          component: () => import('../views/CompanyApps.vue'),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: 'users',
          name: 'Users',
          component: () => import('../views/Users'),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: 'settings',
          name: 'Settings',
          component: () => import('../views/Settings.vue'),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: 'logs',
          name: 'Logs',
          component: () => import('../views/Logs.vue'),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: 'device-logs',
          name: 'Device logs',
          component: () => import('../views/DeviceLogs.vue'),
          meta: {
            requiresAuth: true,
          },
        },
      ],
    },
    {
      path: '/login',
      component: () => import('../layouts/page/Index'),
      children: [
        {
          path: '',
          name: 'Login',
          component: () => import('../views/Login'),
        },
      ],
    },
  ],
})

router.beforeEach((to, from, next) => {
  // if (to.matched.some(record => record.meta.requiresAuth)) {
  //   if (store.getters['authUser/isLoggedIn']) {
  //     next()
  //   } else {
  //     next('/login')
  //   }
  // } else {
  //   next()
  // }
  next()
})

export default router
