<template>
  <v-data-table
    :headers="headers"
    :items="items"
    v-bind="{ ...$attrs, ...(serverSideRendering && { ['server-items-length']: totalServerItems }) }"
    :search="search"
    :loading="loading"
    :options.sync="options"
    :items-per-page="itemsPerPage"
    :footer-props="{
      showFirstLastPage: true,
      'items-per-page-options': itemsPerOptions
    }"
    v-on="$listeners"
  >
    <template
      v-for="(slot,i) in typeSlots"
      v-slot:[`item.${slot.value}`]="{ item }"
    >
      <span
        :key="i"
        @click="onClick(slot, item)"
        v-html="typeConversion(slot.type, item[slot.value])"
      />
    </template>

    <template
      v-for="(_, slot) of $scopedSlots"
      v-slot:[slot]="scope"
    >
      <slot
        :name="slot"
        v-bind="scope"
      />
    </template>

    <template v-slot:top>
      <slot name="top" />

      <v-dialog
        v-model="dialog"
        max-width="1000"
      >
        <v-card>
          <v-card-title>
            <div
              class="w-100 d-flex flex-row justify-space-between align-center"
              style="width:100%"
            >
              <h2>{{ dialogTitle }}</h2>
              <v-btn
                x-small
                color="error"
                fab
                @click="dialog = false"
              >
                <v-icon class="cursor-pointer">
                  mdi-close
                </v-icon>
              </v-btn>
            </div>
          </v-card-title>
          <v-card-text>
            <pre>{{ dialogContent }}</pre>
          </v-card-text>
        </v-card>
      </v-dialog>
    </template>
  </v-data-table>
</template>

<script>
  import moment from 'moment'
  import { format as jsonFormatter } from 'json-string-formatter'

  export default {
    name: 'DataTableWrapper',
    props: {
      headers: { type: Array, default: () => [] },
      items: { type: Array, default: () => [] },
      search: String,
      serverSideRendering: Boolean,
      itemsPerPage: { type: Number, default: 5 },
      itemsPerOptions: { type: Array, default: () => [5, 10, 15, 25] },
    },
    data: () => ({
      dialog: false,
      dialogTitle: '',
      dialogContent: '',
      loading: false,
      totalServerItems: 0,
      options: {},
      optionTimer: undefined,
      isSearching: false,
      searchTimer: undefined,
    }),
    computed: {
      typeSlots () {
        return this?.$attrs?.headers?.filter(x => x.type) || []
      },
    },
    watch: {
      options: {
        handler () {
          clearTimeout(this.optionTimer)
          this.optionTimer = setTimeout(() => {
            if (!this.isSearching) {
              this.loadData()
            }
          }, 500)
        },
        deep: true,
      },
      search: {
        handler () {
          clearTimeout(this.searchTimer)
          this.searchTimer = setTimeout(() => {
            this.isSearching = true
            this.options.page = 1
            this.loadData()
          }, 2500)
        },
      },
    },
    methods: {
      onClick ({ text, type, value }, item) {
        if (type === 'json') {
          this.dialog = true
          this.dialogTitle = text
          this.dialogContent = jsonFormatter(item[value])
        }
      },
      reload () {
        this.isSearching = false
        this.loading = false

        if (this.options.page === 1) {
          this.loadData()
          return
        }

        this.options.page = 1
      },
      loadData () {
        if (!this.serverSideRendering) {
          return
        }

        this.loading = true

        const callback = (items, totalItems) => {
          this.$emit('update:items', items)
          this.totalServerItems = totalItems
          this.isSearching = false
          this.loading = false
        }

        const { page, itemsPerPage } = this.options
        this.$emit('load', { callback, page, itemsPerPage, search: this.search })
      },
      typeConversion (type, value) {
        switch (type) {
          case 'datetime':
            return this.formatDate(value)
          default:
            return value
        }
      },
      formatDate (dateTime) {
        if (dateTime) {
          return moment
            .utc(dateTime)
            .local()
            .format('h:mm:ss a, Do MMMM YYYY')
        } else {
          return ''
        }
      },
    },
  }
</script>
